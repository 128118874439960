import { isPlatformBrowser } from '@angular/common';
import * as i0 from '@angular/core';
import { Injectable, InjectionToken, inject, forwardRef, ElementRef, Renderer2, PLATFORM_ID, EventEmitter, Directive, Input, Output, Pipe, NgModule } from '@angular/core';
import { NG_VALUE_ACCESSOR, COMPOSITION_BUFFER_MODE } from '@angular/forms';
import IMask, { pipe } from 'imask';
export { PIPE_TYPE, pipe } from 'imask';
class DefaultImaskFactory {
  create(el, opts) {
    return IMask(el, opts);
  }
}
DefaultImaskFactory.ɵfac = function DefaultImaskFactory_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DefaultImaskFactory)();
};
DefaultImaskFactory.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: DefaultImaskFactory,
  factory: DefaultImaskFactory.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DefaultImaskFactory, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
const IMASK_FACTORY = new InjectionToken('IMASK_FACTORY', {
  providedIn: 'root',
  factory: () => inject(DefaultImaskFactory)
});
const MASKEDINPUT_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => IMaskDirective),
  multi: true
};
const DEFAULT_IMASK_ELEMENT = elementRef => elementRef.nativeElement;
class IMaskDirective {
  constructor() {
    this.onTouched = () => {};
    this.onChange = () => {};
    this._viewInitialized = false;
    this._composing = false;
    this._writing = false;
    this._elementRef = inject(ElementRef);
    this._renderer = inject(Renderer2);
    this._factory = inject(IMASK_FACTORY);
    this._platformId = inject(PLATFORM_ID);
    this._compositionMode = inject(COMPOSITION_BUFFER_MODE, {
      optional: true
    }) ?? !this._isAndroid();
    this.imaskElement = DEFAULT_IMASK_ELEMENT;
    this.accept = new EventEmitter();
    this.complete = new EventEmitter();
  }
  get element() {
    return this.imaskElement(this._elementRef, this);
  }
  get maskValue() {
    if (!this.maskRef) return this.element.value;
    if (this.unmask === 'typed') return this.maskRef.typedValue;
    if (this.unmask) return this.maskRef.unmaskedValue;
    return this.maskRef.value;
  }
  set maskValue(value) {
    if (this.maskRef) {
      if (this.unmask === 'typed') this.maskRef.typedValue = value;else if (this.unmask) this.maskRef.unmaskedValue = value;else this.maskRef.value = value;
    } else {
      this._renderer.setProperty(this.element, 'value', value);
    }
  }
  ngAfterViewInit() {
    if (this.imask) this.initMask();
    this._viewInitialized = true;
  }
  ngOnChanges(changes) {
    if (changes['elementRef'] && !this.imaskElement) this.imaskElement = DEFAULT_IMASK_ELEMENT;
    if (!changes['imask'] || !this._viewInitialized) return;
    if (this.imask) {
      if (this.maskRef) this.maskRef.updateOptions(this.imask);else {
        this.initMask();
        this.onChange(this.maskValue);
      }
    } else {
      this.destroyMask();
    }
  }
  destroyMask() {
    if (this.maskRef) {
      this.maskRef.destroy();
      delete this.maskRef;
    }
  }
  ngOnDestroy() {
    this.destroyMask();
    this.accept.complete();
    this.complete.complete();
  }
  beginWrite(value) {
    this._writing = true;
    this._writingValue = value;
  }
  endWrite() {
    this._writing = false;
    return this._writingValue;
  }
  writeValue(value) {
    value = value == null && this.unmask !== 'typed' ? '' : value;
    if (this.maskRef) {
      this.beginWrite(value);
      this.maskValue = value;
      this.endWrite();
    } else {
      this._renderer.setProperty(this.element, 'value', value);
    }
  }
  _onAccept() {
    const value = this.maskValue;
    // if value was not changed during writing don't fire events
    // for details see https://github.com/uNmAnNeR/imaskjs/issues/136
    if (this._writing && value === this.endWrite()) return;
    this.onChange(value);
    this.accept.emit(value);
  }
  _onComplete() {
    this.complete.emit(this.maskValue);
  }
  initMask() {
    this.maskRef = this._factory.create(this.element, this.imask).on('accept', this._onAccept.bind(this)).on('complete', this._onComplete.bind(this));
  }
  setDisabledState(isDisabled) {
    this._renderer.setProperty(this.element, 'disabled', isDisabled);
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  _handleInput(value) {
    // if mask is attached all input goes throw mask
    if (this.maskRef) return;
    if (!this._compositionMode || this._compositionMode && !this._composing) {
      this.onChange(value);
    }
  }
  _compositionStart() {
    this._composing = true;
  }
  _compositionEnd(value) {
    this._composing = false;
    this._compositionMode && this._handleInput(value);
  }
  _isAndroid() {
    return isPlatformBrowser(this._platformId) && /android (\d+)/.test(navigator.userAgent.toLowerCase());
  }
}
IMaskDirective.ɵfac = function IMaskDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IMaskDirective)();
};
IMaskDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: IMaskDirective,
  selectors: [["", "imask", ""]],
  hostBindings: function IMaskDirective_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("input", function IMaskDirective_input_HostBindingHandler($event) {
        return ctx._handleInput($event.target.value);
      })("blur", function IMaskDirective_blur_HostBindingHandler() {
        return ctx.onTouched();
      })("compositionstart", function IMaskDirective_compositionstart_HostBindingHandler() {
        return ctx._compositionStart();
      })("compositionend", function IMaskDirective_compositionend_HostBindingHandler($event) {
        return ctx._compositionEnd($event.target.value);
      });
    }
  },
  inputs: {
    imask: "imask",
    unmask: "unmask",
    imaskElement: "imaskElement"
  },
  outputs: {
    accept: "accept",
    complete: "complete"
  },
  exportAs: ["imask"],
  standalone: true,
  features: [i0.ɵɵProvidersFeature([MASKEDINPUT_VALUE_ACCESSOR]), i0.ɵɵNgOnChangesFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IMaskDirective, [{
    type: Directive,
    args: [{
      selector: '[imask]',
      standalone: true,
      exportAs: 'imask',
      host: {
        '(input)': '_handleInput($event.target.value)',
        '(blur)': 'onTouched()',
        '(compositionstart)': '_compositionStart()',
        '(compositionend)': '_compositionEnd($event.target.value)'
      },
      providers: [MASKEDINPUT_VALUE_ACCESSOR]
    }]
  }], null, {
    imask: [{
      type: Input
    }],
    unmask: [{
      type: Input
    }],
    imaskElement: [{
      type: Input
    }],
    accept: [{
      type: Output
    }],
    complete: [{
      type: Output
    }]
  });
})();

/*
 * Transforms value through mask
 * Takes mask and optionally `from` and `to` pipe types.
 * Usage:
 *   value | imask:MASK_OR_MASKED:opt_from:opt_to
 * Example:
 *   {{ 2 | imask:mask }}
*/
class IMaskPipe {
  transform(...args) {
    return pipe(...args);
  }
}
IMaskPipe.ɵfac = function IMaskPipe_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IMaskPipe)();
};
IMaskPipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "imask",
  type: IMaskPipe,
  pure: true,
  standalone: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IMaskPipe, [{
    type: Pipe,
    args: [{
      name: 'imask',
      standalone: true
    }]
  }], null, null);
})();
class IMaskModule {}
IMaskModule.ɵfac = function IMaskModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IMaskModule)();
};
IMaskModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: IMaskModule
});
IMaskModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IMaskModule, [{
    type: NgModule,
    args: [{
      imports: [IMaskDirective, IMaskPipe],
      exports: [IMaskDirective, IMaskPipe]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { DEFAULT_IMASK_ELEMENT, DefaultImaskFactory, IMASK_FACTORY, IMaskDirective, IMaskModule, IMaskPipe, MASKEDINPUT_VALUE_ACCESSOR };
