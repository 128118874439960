import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  InvoiceNotesGQL,
  CreateInvoiceNoteGQL,
  InvoiceNotesDocument,
  InvoiceNotesQuery,
  InvoiceNoteEdge,
} from '@generated/graphql';
import { map } from 'rxjs/operators';
import { QUERIES_LIMIT } from '@shared/constants';
import { ApolloQueryResult } from '@apollo/client/core';
import { Observable } from 'rxjs';
import { CognitoAuthService } from '@auth/services/cognito-auth.service';
import { NotesComponent } from './notes.component';

@Component({
  selector: 'invoice-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceNotesComponent extends NotesComponent {
  @Input() invoiceId: string;

  notes$: Observable<InvoiceNoteEdge[]>;

  constructor(
    private invoiceNotesGQL: InvoiceNotesGQL,
    private createInvoiceNoteGQL: CreateInvoiceNoteGQL,
    private cognitoAuthService: CognitoAuthService,
  ) {
    super();
  }

  loadNotes(): void {
    const params = {
      invoiceId: this.invoiceId,
      last: QUERIES_LIMIT,
    };

    this.notes$ = this.invoiceNotesGQL.watch(params, this.options).valueChanges.pipe(
      map(({ data }: ApolloQueryResult<InvoiceNotesQuery>) => {
        setTimeout(this.scrollToLast.bind(this));
        return data.invoiceNotes?.edges as InvoiceNoteEdge[];
      }),
    );
  }

  addComment(text: string) {
    const params = {
      invoiceId: this.invoiceId,
      userId: this.cognitoAuthService.user.id,
      text,
    };

    this.createInvoiceNoteGQL
      .mutate(params, { refetchQueries: [InvoiceNotesDocument] })
      .subscribe(() => {
        this.formControl.setValue('');
        this.resize();
      });
  }
}
