import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { RadarFormAbstract } from '@shared/components/radar-form-abstract';
import { DATETIME_V3, TEXTAREA_V2 } from '@app/formly/helpers/fields';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'due-date-form-v3',
  templateUrl: './due-date-form-v3.component.html',
  styleUrls: ['./due-date-form-v3.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DueDateFormV3Component extends RadarFormAbstract implements OnInit {
  formGroup = new UntypedFormGroup({});
  autoUpdate = false;

  ngOnInit(): void {
    super.ngOnInit();

    this.fields = [
      DATETIME_V3({
        key: 'dueDate',
      }),
      TEXTAREA_V2({
        key: 'dueDescription',
        props: {
          label: 'Description',
          rows: 3,
          placeholder: 'Note on due date or follow-up',
        },
        modelOptions: {
          debounce: {
            default: 300,
          },
        },
      }),
    ];
  }
}
