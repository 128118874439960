import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AIRLINES_V2, INPUT_V2 } from '@app/formly/helpers/fields';
import { AirlineNode } from '@app/generated/graphql';
import { RadarFormAbstract } from '@app/shared/components/radar-form-abstract';
import { AirlinesService, mapAirlineToDD } from '@app/shared/services/airlines/airlines.service';

@Component({
  selector: 'confirmation-form',
  templateUrl: './confirmation-form.component.html',
  styleUrls: ['./confirmation-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationFormComponent extends RadarFormAbstract implements OnInit {
  @Output() deleteConfirmation = new EventEmitter();

  constructor(private airlinesService: AirlinesService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.fields = [
      AIRLINES_V2(
        {
          key: 'airlineId',
          props: {
            label: 'Airline',
            placeholder: 'Choose a airline',
            initial: this.data?.airline ? mapAirlineToDD(this.data?.airline as AirlineNode) : null,
          },
        },
        this.airlinesService.getAirlines(),
      ),
      INPUT_V2({
        key: 'code',
        props: {
          label: 'Number',
          placeholder: 'Write a number',
        },
      }),
    ];
  }
}
