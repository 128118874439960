import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BookingNode, UserType } from '@app/generated/graphql';
import { FeatureToggleService, RdrFeature } from '@app/ui/services/feature-toggle.service';
@Component({
  selector: 'booking-revenue-head',
  templateUrl: './booking-revenue-head.component.html',
  styleUrls: ['./booking-revenue-head.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingRevenueHeadComponent implements OnInit {
  UserType = UserType;

  constructor(private featureToggleService: FeatureToggleService) {}

  isPnrPageDisabled: boolean;

  @Input() booking: BookingNode;
  @Input() showOldPnrs: boolean; // todo remove after fe toggle

  ngOnInit() {
    this.isPnrPageDisabled = !this.featureToggleService.isFeatureEnabled(RdrFeature.pnr_page);
  }
}
