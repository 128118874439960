import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  MilesAccountNoteEdge,
  MilesAccountNotesGQL,
  CreateMilesAccountNoteGQL,
  MilesAccountNotesQuery,
  MilesAccountNotesDocument,
} from '@generated/graphql';
import { map } from 'rxjs/operators';
import { QUERIES_LIMIT } from '@shared/constants';
import { ApolloQueryResult } from '@apollo/client/core';
import { Observable } from 'rxjs';
import { NotesComponent } from './notes.component';

@Component({
  selector: 'miles-account-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MilesAccountNotesComponent extends NotesComponent {
  @Input() milesAccountId: string;

  notes$: Observable<MilesAccountNoteEdge[]>;

  constructor(
    private milesAccountNotesGQL: MilesAccountNotesGQL,
    private createMilesAccountNoteGQL: CreateMilesAccountNoteGQL,
  ) {
    super();
  }

  loadNotes(): void {
    const params = {
      milesAccountId: this.milesAccountId,
      last: QUERIES_LIMIT,
    };

    this.notes$ = this.milesAccountNotesGQL.watch(params, this.options).valueChanges.pipe(
      map(({ data }: ApolloQueryResult<MilesAccountNotesQuery>) => {
        setTimeout(this.scrollToLast.bind(this));
        return data.milesAccountNotes?.edges as MilesAccountNoteEdge[];
      }),
    );
  }

  addComment(text: string) {
    const params = {
      milesAccountId: this.milesAccountId,
      text,
    };

    this.createMilesAccountNoteGQL
      .mutate(params, { refetchQueries: [MilesAccountNotesDocument] })
      .subscribe(() => {
        this.formControl.setValue('');
        this.resize();
      });
  }
}
