import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AnchorScrollService, TopOffset } from '@shared/services/anchor-scroll.service';

@Component({
  selector: 'anchor-item',
  templateUrl: './anchor-item.component.html',
  styleUrls: ['./anchor-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnchorItemComponent {
  @Input() header: 'big' | 'small' = 'big';
  @Input() for: string;

  constructor(private anchorScrollService: AnchorScrollService) {}

  handleScroll(e: Event, id: string): void {
    e.preventDefault();
    this.anchorScrollService.handleScroll(
      id,
      this.header === 'big' ? TopOffset.bigHeader : TopOffset.smallHeader,
    );
  }
}
