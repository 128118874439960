import { Injectable } from '@angular/core';
import { CognitoAuthService } from '@app/auth/services/cognito-auth.service';
import {
  DeleteOrderETicketGQL,
  DeleteOrderETicketMutationVariables,
  UpsertOrderETicketGQL,
  UpsertOrderETicketMutationVariables,
  GetPresignedUrlGQL,
  GetPresignedUrlQueryVariables,
} from '@app/generated/graphql';

@Injectable({
  providedIn: 'root',
})
export class OrderBookingEticketsV2Service {
  constructor(
    private upsertOrderETicketGQL: UpsertOrderETicketGQL,
    private deleteOrderETicketGQL: DeleteOrderETicketGQL,
    private getPresignedUrlGQL: GetPresignedUrlGQL,
    private cognitoAuthService: CognitoAuthService,
  ) {}

  upsertOrderETicket(params: UpsertOrderETicketMutationVariables) {
    const withInvoices = this.cognitoAuthService.isAccountant;
    params = { ...params, withInvoices };

    return this.upsertOrderETicketGQL.mutate(params);
  }

  deleteOrderETicket(params: DeleteOrderETicketMutationVariables) {
    const withInvoices = this.cognitoAuthService.isAccountant;
    params = { ...params, withInvoices };

    return this.deleteOrderETicketGQL.mutate(params);
  }

  getPresignedUrl(params: GetPresignedUrlQueryVariables) {
    return this.getPresignedUrlGQL.fetch(params);
  }
}
