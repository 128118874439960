import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { FlightRequestPageV2Service } from '@app/pages/flight-requests/services/flight-request-page-v2.service';
import {
  ChangeSearchTaskStatusType,
  FeedbackReasonNode,
  FeedbackTypeEnum,
  IdInput,
  SearchTaskCommentInput,
  SearchTaskNode,
  SetSearchTaskFeedbackMutationVariables,
  UserType,
} from '@app/generated/graphql';
import { Observable } from 'rxjs';
import { GraphqlID } from '@app/shared/types';
import { FormLoadingStateService } from '@app/shared/services/form-loading-state.service';

@Component({
  selector: 'search-task-comments',
  templateUrl: './search-task-comments.component.html',
  styleUrls: ['./search-task-comments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchTaskCommentsComponent implements OnInit, AfterViewInit, AfterViewChecked {
  UserType = UserType;
  FeedbackTypeEnum = FeedbackTypeEnum;
  ChangeSearchTaskStatusType = ChangeSearchTaskStatusType;

  onlyReopenButton = true;
  showedActions: boolean;
  formControl = new FormControl('');

  reasons$: Observable<FeedbackReasonNode[]>;
  feedback: FeedbackTypeEnum;
  selectedReasonsIds = new Set<GraphqlID>();
  selectedReasons: IdInput[] = [];

  @Input() flightRequestId?: GraphqlID;
  @Input() searchTask?: SearchTaskNode;

  @Output() sendTaskCommentEvent = new EventEmitter<SearchTaskCommentInput>();
  @Output() changeTaskStatusEvent = new EventEmitter<ChangeSearchTaskStatusType>();
  @Output() closeComments = new EventEmitter();
  @Output() reopenTask = new EventEmitter();
  @Output() saveSearchTaskFeedback = new EventEmitter<SetSearchTaskFeedbackMutationVariables>();

  @HostListener('document:keydown.escape', ['$event']) onEscape(): void {
    this.closeComments.emit();
  }

  constructor(
    private flightRequestPageV2Service: FlightRequestPageV2Service,
    private formLoadingStateService: FormLoadingStateService,
  ) {}

  ngOnInit() {
    this.reasons$ = this.flightRequestPageV2Service.getFeedbackReasons();

    if (this.searchTask?.feedback) {
      this.feedback = this.searchTask.feedback as unknown as FeedbackTypeEnum;
    }

    if (this.searchTask?.feedbackReasons?.edges?.length) {
      this.searchTask?.feedbackReasons?.edges?.forEach(({ node }) => {
        this.selectedReasons.push(node?.id as unknown as IdInput);
        this.selectedReasonsIds.add(node?.id as GraphqlID);
      });
    }
  }

  ngAfterViewInit() {
    this.scrollToLast();
  }

  ngAfterViewChecked() {
    this.scrollToLast();
  }

  scrollToLast() {
    const container = document.getElementById('search-task-comments');

    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  }

  sendTaskComment() {
    const params: SearchTaskCommentInput = {
      searchTaskId: this.searchTask?.id as GraphqlID,
      description: this.formControl.value as string,
    };

    this.sendTaskCommentEvent.emit(params);

    this.formControl.setValue('');
    setTimeout(() => this.scrollToLast(), 2000);
  }

  changeTaskStatus(status: ChangeSearchTaskStatusType) {
    this.formLoadingStateService.isLoading$.next(true);

    this.changeTaskStatusEvent.emit(status);
  }

  setFeedback(feedback: FeedbackTypeEnum) {
    this.formLoadingStateService.isLoading$.next(true);

    this.feedback = feedback;
    this.onlyReopenButton = false;

    const input = {
      searchTaskId: this.searchTask?.id as GraphqlID,
      feedback: this.feedback,
    };

    this.saveSearchTaskFeedback.emit({ input });
  }

  setReason(reasonId: GraphqlID) {
    if (this.selectedReasonsIds.has(reasonId)) {
      this.selectedReasonsIds.delete(reasonId);
    } else {
      this.selectedReasonsIds.add(reasonId);
    }
  }

  sendFeedback() {
    this.formLoadingStateService.isLoading$.next(true);

    this.selectedReasons = Array.from(this.selectedReasonsIds).map((id) => ({ id }));

    const input = {
      searchTaskId: this.searchTask?.id as GraphqlID,
      feedback: this.feedback,
      reasons: this.selectedReasons,
    };

    this.saveSearchTaskFeedback.emit({ input });
  }
}
