<card>
  <div class="d-flex align-items-center v2-mb-3">
    <typography-v2 look="title-bold" dataId="title">Requests</typography-v2>

    <button-icon-v2
      *ngIf="!readonly"
      [modalTpl]="createFlightRequestModalTemplate"
      showModalOnClick
      key="plus-v-2"
      size="md"
      color="var(--color-black)"
      class="v2-ml-1 add-new-request"
      data-id="add-flight-request"
    ></button-icon-v2>
  </div>

  <ng-container *ngIf="columns && flightRequests; else loadingTemplate">
    <div *ngIf="flightRequests?.length; else emptyState" class="d-flex flex-column">
      <radar-table
        class="w-100"
        (rowClick)="flightRequestClick($event)"
        [columns]="columns"
        [rows]="flightRequests"
        [readonly]="true"
      >
      </radar-table>
    </div>
  </ng-container>
</card>

<ng-template #loadingTemplate>
  <ng-container *ngFor="let x of [].constructor(3)">
    <div class="d-flex v2-pt-2 v2-pb-2 w-100">
      <skeleton-loader-small class="v2-mx-2" [height]="16" [width]="160"></skeleton-loader-small>
      <skeleton-loader-small class="v2-mx-2" [height]="16"></skeleton-loader-small>
      <skeleton-loader-small class="v2-mx-2" [height]="16" [width]="170"></skeleton-loader-small>
      <skeleton-loader-small class="v2-mx-2" [height]="16" [width]="160"></skeleton-loader-small>
      <skeleton-loader-small class="v2-mx-2" [height]="16" [width]="90"></skeleton-loader-small>
    </div>
  </ng-container>
</ng-template>

<ng-template #emptyState>
  <typography-v2 class="text-black-50">Has no requests yet</typography-v2>
</ng-template>

<ng-template #statusTemplate let-status="value">
  <colored-status-v2 data-id="status" [status]="status"> {{ status | label }}</colored-status-v2>
</ng-template>

<ng-template #flightRequestTripInfoTemplate let-flightRequestTrips="value">
  <typography-v2 *ngIf="flightRequestTrips?.edges?.length" dataId="flight-info"
    >{{ flightRequestTrips?.edges![0]?.node?.departureAirport?.locationCode || '-' }} &rarr; {{
    flightRequestTrips?.edges![0]?.node?.arrivalAirport?.locationCode || '-' }}</typography-v2
  >
</ng-template>

<ng-template #changeRequestTemplate let-row="row">
  <typography-v2 *ngIf="row.changeToFlightRequestId">Change</typography-v2>
</ng-template>

<ng-template #flightDateTemplate let-flightRequestTrips="value">
  <typography-v2 *ngIf="flightRequestTrips?.edges?.length" dataId="flight-date"
    >{{ flightRequestTrips?.edges![0]?.node?.departureAt | rdrDate: 'short_date'}}
  </typography-v2>
</ng-template>

<ng-template #createFlightRequestModalTemplate>
  <modal-v2 [header]="'Add Flight Request'">
    <flight-request-flight-form-v2
      [allowDelete]="false"
      [addButtonText]="'Add Flight Request'"
      (formSubmit)="createNewFlightRequest.emit($event)"
    ></flight-request-flight-form-v2>
  </modal-v2>
</ng-template>
