import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FlightRequestNode } from '@app/generated/graphql';
import { FormSubmitData } from '@app/shared/components/radar-form-abstract';
import {
  RadarTableColumn,
  RadarTableColumnType,
  RadarTableRowClick,
} from '@app/shared/components/radar-table/radar-table.types';
import { FlightFormState } from '@app/shared/forms/flight-request-flight-form-v2/flight-request-flight-form-v2.component';

@Component({
  selector: 'client-requests-section',
  templateUrl: './client-requests-section.component.html',
  styleUrls: ['./client-requests-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientRequestsSectionComponent implements AfterViewInit {
  @Input() flightRequests: FlightRequestNode[];
  @Input() readonly: boolean;

  @Output() clickOnFlightRequest = new EventEmitter();
  @Output() createNewFlightRequest = new EventEmitter<FormSubmitData<FlightFormState>>();

  constructor(private cdr: ChangeDetectorRef) {}

  columns: RadarTableColumn[];

  @ViewChild('flightRequestTripInfoTemplate') flightRequestTripInfoTemplate: TemplateRef<any>;
  @ViewChild('flightDateTemplate') flightDateTemplate: TemplateRef<any>;
  @ViewChild('statusTemplate') statusTemplate: TemplateRef<any>;

  ngAfterViewInit() {
    this.columns = [
      {
        prop: 'status',
        name: 'Stage',
        cellTemplate: this.statusTemplate,
        width: 160 + 64,
      },
      {
        prop: 'id',
        name: '#',
      },
      {
        prop: 'flightRequestTrips',
        name: 'Date',
        cellTemplate: this.flightDateTemplate,
      },
      {
        prop: 'flightRequestTrips',
        name: 'Flight',
        cellTemplate: this.flightRequestTripInfoTemplate,
      },
      {
        prop: 'createdAt',
        name: 'Created',
        cellType: RadarTableColumnType.Date,
      },
    ];
    window.setTimeout(() => this.cdr.markForCheck());
  }

  flightRequestClick(rowClick: RadarTableRowClick<FlightRequestNode>) {
    this.clickOnFlightRequest.emit({ event: rowClick.event, flightRequest: rowClick.row });
  }
}
