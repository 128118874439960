import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  LeadNoteEdge,
  LeadNotesGQL,
  LeadNotesQuery,
  CreateNoteGQL,
  LeadNotesDocument,
} from '@generated/graphql';
import { map } from 'rxjs/operators';
import { QUERIES_LIMIT } from '@shared/constants';
import { ApolloQueryResult } from '@apollo/client/core';
import { Observable } from 'rxjs';
import { NotesComponent } from './notes.component';

@Component({
  selector: 'lead-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeadNotesComponent extends NotesComponent {
  @Input() leadId: string;

  notes$: Observable<LeadNoteEdge[]>;

  constructor(
    private leadNotesGQL: LeadNotesGQL,
    private createLeadNoteGQL: CreateNoteGQL,
  ) {
    super();
  }

  loadNotes(): void {
    const params = {
      leadId: this.leadId,
      last: QUERIES_LIMIT,
    };

    this.notes$ = this.leadNotesGQL.watch(params, this.options).valueChanges.pipe(
      map(({ data }: ApolloQueryResult<LeadNotesQuery>) => {
        setTimeout(this.scrollToLast.bind(this));
        return data.leadNotes?.edges as LeadNoteEdge[];
      }),
    );
  }

  addComment(text: string) {
    const params = {
      leadId: this.leadId,
      text,
    };

    this.createLeadNoteGQL.mutate(params, { refetchQueries: [LeadNotesDocument] }).subscribe(() => {
      this.formControl.setValue('');
      this.resize();
    });
  }
}
