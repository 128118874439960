import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  AIRLINES_V2,
  CHIPS_V2,
  DATE_V3,
  EMAIL_V2,
  FILE_V2,
  INPUT_V2,
  PHONE_V2,
  REPEAT_V2,
  SELECT_V2,
  TEXTAREA_V2,
  TYPEAHEAD_V2,
} from '@app/formly/helpers/fields';
import { filterDDWithCode } from '@app/formly/types/v2/typeahead-v2/typeahead-filters';
import {
  CountryNode,
  FrequentFlyerNumberConnection,
  GenderType,
  PassengerNode,
} from '@app/generated/graphql';
import { OrdersPageV2Service } from '@app/pages/orders/pages/order-details-page-v2/services/orders-page-v2.service';
import { RadarFormAbstract } from '@app/shared/components/radar-form-abstract';
import { PASSENGER_TYPE } from '@app/shared/constants';
import { AirlinesService, mapAirlineToDD } from '@app/shared/services/airlines/airlines.service';
import {
  CountriesService,
  getInitialCountryDDItem,
} from '@app/shared/services/countries/countries.service';
import { GraphqlID } from '@app/shared/types';
import { DropdownItem } from '@app/ui/components/dropdown.model';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { map, of } from 'rxjs';

@Component({
  selector: 'passenger-form-v2',
  templateUrl: './passenger-form-v2.component.html',
  styleUrls: ['./passenger-form-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PassengerFormV2Component extends RadarFormAbstract implements OnInit {
  @Input() isOrder: boolean;
  @Input() clientId: GraphqlID;
  @Input() passengers: PassengerNode[];
  @Input() totalPassengers?: number;

  @Output() deletePassenger = new EventEmitter();

  constructor(
    private countriesService: CountriesService,
    private ordersPageV2Service: OrdersPageV2Service,
    private airlinesService: AirlinesService,
  ) {
    super();
  }

  get hasTotalPassengersError() {
    if (this.formModel['type'] === PASSENGER_TYPE.EXISTING) {
      return this.formModel['existingPassengerIds']?.length > (this.totalPassengers || 0);
    }

    return false;
  }

  ngOnInit(): void {
    super.ngOnInit();

    const typeField = this.isOrder
      ? [
          SELECT_V2(
            {
              key: 'type',
              props: {
                initial: typeDDItems[0],
              },
              defaultValue: PASSENGER_TYPE.NEW,
            },
            of(typeDDItems),
          ),
        ]
      : [];

    const existingPassengerField = this.isOrder
      ? [
          CHIPS_V2(
            {
              key: 'existingPassengerIds',
              props: {
                label: 'Passenger',
                placeholder: 'Choose a passenger',
                required: true,
              },
              hideExpression: 'model.type === "NEW"',
            },
            this.ordersPageV2Service
              .getExistingPassengers({ id: this.clientId })
              .pipe(map(this.filterExistingPassengers.bind(this))),
          ),
        ]
      : [];

    this.fields = [
      ...typeField,
      {
        fieldGroupClassName: 'd-flex w-100',
        fieldGroup: [
          INPUT_V2({
            key: 'firstName',
            className: 'col-4',
            props: { label: 'First Name', placeholder: 'First Name', required: true },
          }),
          INPUT_V2({
            key: 'middleName',
            className: 'col-4',
            props: { label: 'Middle Name', placeholder: 'Middle Name' },
          }),
          INPUT_V2({
            key: 'lastName',
            className: 'col-4',
            props: { label: 'Last Name', placeholder: 'Last Name', required: true },
          }),
        ],
        hideExpression: 'model.type === "EXISTING"',
      },
      {
        fieldGroupClassName: 'd-flex w-100',
        fieldGroup: [
          DATE_V3({
            key: 'birthday',
            className: 'col-6',
            props: { label: 'Date of Birth', clearable: true, onlyDatesInThePast: true },
            validators: {
              validation: ['only-date-in-past'],
            },
          }),
          SELECT_V2(
            {
              key: 'gender',
              className: 'col-6',
              props: {
                label: 'Gender',
                initial: this.data?.gender
                  ? getInitialGenderDDItem(this.data?.gender as GenderType)
                  : null,
                placeholder: 'Gender',
              },
            },
            of(genderDDItems),
          ),
        ],
        hideExpression: 'model.type === "EXISTING"',
      },
      {
        fieldGroupClassName: 'd-flex w-100',
        fieldGroup: [
          PHONE_V2({
            key: 'phone',
            className: 'col-6',
            props: { label: 'Phone' },
          }),
          EMAIL_V2({
            key: 'email',
            className: 'col-6',
            props: { label: 'Email' },
          }),
        ],
        hideExpression: 'model.type === "EXISTING"',
      },
      {
        fieldGroupClassName: 'd-flex w-100',
        fieldGroup: [
          INPUT_V2({
            key: 'passport.number',
            className: 'col-6',
            props: { label: 'Passport Number', placeholder: '000000000' },
          }),
          DATE_V3({
            key: 'passport.expiration',
            className: 'col-6',
            props: { label: 'Expiration Date' },
            validators: {
              validation: ['only-date-in-future'],
            },
          }),
        ],
        hideExpression: 'model.type === "EXISTING"',
      },
      {
        fieldGroupClassName: 'd-flex w-100',
        fieldGroup: [
          TYPEAHEAD_V2(
            {
              key: 'passport.countryId',
              className: 'col-6',
              props: {
                label: 'Country of Issue',
                initial: this.data?.passport?.countryId
                  ? getInitialCountryDDItem(this.data?.passport?.country as CountryNode)
                  : null,
                placeholder: 'USA',
                filterCallback: filterDDWithCode,
              },
            },
            this.countriesService.getCountriesAsDropDownItems(),
          ),
          FILE_V2({
            key: 'passport.fileDocument.id',
            className: 'col-6',
            props: {
              label: 'Document',
              filetype: 'filePassportId',
              originName: this.data?.passport?.fileDocument?.originName,
            },
          }),
        ],
        hideExpression: 'model.type === "EXISTING"',
      },
      TEXTAREA_V2({
        key: 'note',
        props: { label: 'Contact Notes', placeholder: 'Contact Notes' },
        hideExpression: 'model.type === "EXISTING"',
      }),
      {
        fieldGroupClassName: 'd-flex w-100',
        fieldGroup: [
          INPUT_V2({
            key: 'knownTravelerNumber',
            className: 'col-6',
            props: { label: 'Known Traveler Number' },
          }),
          INPUT_V2({
            key: 'globalEntry',
            className: 'col-6',
            props: { label: 'Global Entry', placeholder: '000000000' },
          }),
        ],
        hideExpression: 'model.type === "EXISTING"',
      },
      REPEAT_V2({
        key: 'flyerNumbers',
        className: 'w-100',
        props: {
          label: 'Frequent Flyer #',
          startWithEmpty: true,
        },
        validators: {
          validation: ['frequent-flyer-numbers'],
        },
        fieldArray: {
          fieldGroupClassName: 'd-flex w-100',
          fieldGroup: [
            AIRLINES_V2(
              {
                key: 'airlineId',
                className: 'col-6',
                props: { label: 'Airline' },
                expressionProperties: {
                  'props.initial': (_model: any, _formState: any, field?: FormlyFieldConfig) => {
                    const frequentFlyerNumberConnection = this.data[
                      'frequentFlyerNumbers'
                    ] as FrequentFlyerNumberConnection;

                    const frequentFlyerNumberNode = frequentFlyerNumberConnection?.edges.find(
                      ({ node }) => node?.airlineId === field?.formControl?.value,
                    )?.node;

                    if (frequentFlyerNumberNode?.airline) {
                      return mapAirlineToDD(frequentFlyerNumberNode.airline);
                    }

                    return null;
                  },
                },
              },
              this.airlinesService.getAirlines(),
            ),
            INPUT_V2({
              key: 'number',
              className: 'col-6',
              props: { label: 'Frequent Flyer #' },
            }),
          ],
        },
        hideExpression: () => {
          return this.formModel['type'] === 'EXISTING';
        },
      }),
      ...existingPassengerField,
    ];
  }

  filterExistingPassengers(passengersDDItems: DropdownItem[]) {
    return passengersDDItems.filter((passengerDDItem) => {
      return !this.passengers.find(({ id }) => id === passengerDDItem.value);
    });
  }
}

const genderDDItems = [
  { label: 'Male', value: GenderType.Male },
  { label: 'Female', value: GenderType.Female },
];

const getInitialGenderDDItem = (value?: GenderType) => {
  return value === GenderType.Male ? genderDDItems[0] : genderDDItems[1];
};

const typeDDItems = [
  { label: 'New', value: PASSENGER_TYPE.NEW },
  { label: 'Existing', value: PASSENGER_TYPE.EXISTING },
];
