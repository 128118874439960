import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { COUNT_V2, CURRENCY_V2 } from '@app/formly/helpers/fields';
import { OrderNode } from '@app/generated/graphql';
import { RadarFormAbstract } from '@app/shared/components/radar-form-abstract';
import { AgeGroupLabels } from '@app/shared/constants';
import { OrdersService } from '@app/shared/services/orders/orders.service';
import { UntilDestroy, UntilDestroyed } from '@app/shared/utils/until-destroy';
import { debounceTime } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'selling-price-form',
  templateUrl: './selling-price-form.component.html',
  styleUrls: ['./selling-price-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SellingPriceFormComponent extends RadarFormAbstract implements OnInit {
  orderPriceEstimateResponse: OrderPriceEstimateResponse | null;

  @Input() order: OrderNode;

  constructor(
    private ordersService: OrdersService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.fields = [
      {
        fieldGroupClassName: 'd-flex w-100',
        fieldGroup: [
          COUNT_V2({
            key: 'numberOfAdults',
            className: 'col-7',
            props: {
              extra: {
                title: 'Adults',
                info: AgeGroupLabels.Adult,
              },
              min: 1,
              readonly: this.readonly,
            },
            defaultValue: 0,
          }),
          CURRENCY_V2({
            key: 'soldAmountPerAdult',
            className: 'col-4 ms-auto',
            props: {
              readonly: this.readonly,
            },
          }),
        ],
      },
      {
        fieldGroupClassName: 'd-flex w-100',
        fieldGroup: [
          COUNT_V2({
            key: 'numberOfChildren',
            className: 'col-7',
            props: {
              extra: {
                title: 'Children',
                info: AgeGroupLabels.Child,
              },
              readonly: this.readonly,
            },
            defaultValue: 0,
          }),
          CURRENCY_V2({
            key: 'soldAmountPerChild',
            className: 'col-4 ms-auto',
            props: {
              readonly: this.readonly,
            },
          }),
        ],
      },
      {
        fieldGroupClassName: 'd-flex w-100',
        fieldGroup: [
          COUNT_V2({
            key: 'numberOfInfants',
            className: 'col-7',
            props: {
              extra: {
                title: 'Infants',
                info: AgeGroupLabels.Infant,
              },
              readonly: this.readonly,
            },
            defaultValue: 0,
          }),
          CURRENCY_V2({
            key: 'soldAmountPerInfant',
            className: 'col-4 ms-auto',
            props: {
              readonly: this.readonly,
            },
          }),
        ],
      },
    ];

    this.formGroup.valueChanges
      .pipe(UntilDestroyed(this))
      .pipe(debounceTime(500))
      .subscribe((values: OrderPriceEstimateParams) => this.orderPriceEstimate(values));
  }

  orderPriceEstimate(values: OrderPriceEstimateParams) {
    this.orderPriceEstimateResponse = null;
    this.changeDetectorRef.detectChanges();

    const params = {
      numberOfAdults: values.numberOfAdults || 0,
      numberOfChildren: values.numberOfChildren || 0,
      numberOfInfants: values.numberOfInfants || 0,
      pricePerAdult: values.soldAmountPerAdult || 0,
      pricePerChild: values.soldAmountPerChild || 0,
      pricePerInfant: values.soldAmountPerInfant || 0,
      orderId: this.order.id,
    };

    this.ordersService
      .orderPriceEstimate(params)
      .pipe(UntilDestroyed(this))
      .subscribe((response) => {
        this.orderPriceEstimateResponse = response;
        this.changeDetectorRef.detectChanges();
      });
  }
}

export type OrderPriceEstimateParams = {
  numberOfAdults: number;
  numberOfChildren: number;
  numberOfInfants: number;
  soldAmountPerAdult: number;
  soldAmountPerChild: number;
  soldAmountPerInfant: number;
  orderId: number;
};

export type OrderPriceEstimateResponse = {
  grossProfitAllAdults: number;
  grossProfitAllChildren: number;
  grossProfitAllInfants: number;
  grossProfitPerAdult: number;
  grossProfitPerChild: number;
  grossProfitPerInfant: number;
  totalGrossProfit: number;

  costPerAdult: number;
  costPerChild: number;
  costPerInfant: number;
  costAllAdults: number;
  costAllChildren: number;
  costAllInfants: number;
  totalCost: number;

  cardProcessingFeeAdult: number;
  cardProcessingFeeChild: number;
  cardProcessingFeeInfant: number;
  cardProcessingFeeAdults: number;
  cardProcessingFeeChildren: number;
  cardProcessingFeeInfants: number;
  totalCardProcessingFee: number;

  totalPrice: number;

  additionalSoldAmounts: { type: string; amount: number }[];
};
