import { Inject, Injectable } from '@angular/core';
import { PageScrollService } from 'ngx-page-scroll-core';
import { DOCUMENT } from '@angular/common';

export enum TopOffset {
  bigHeader = 40 + 120,
  smallHeader = 40 + 62,
}

@Injectable({
  providedIn: 'root',
})
export class AnchorScrollService {
  constructor(
    private pageScrollService: PageScrollService,
    @Inject(DOCUMENT) private document: any,
  ) {}

  handleScroll(id: string, topOffset: TopOffset): void {
    this.pageScrollService.scroll({
      document: this.document,
      scrollTarget: `#${id}`,
      scrollOffset: topOffset,
    });
  }
}
