import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import {
  ETicketNode,
  FileActionEnum,
  OrderNode,
  UpsertOrderETicketMutationVariables,
  UserType,
} from '@app/generated/graphql';
import {
  FormSubmitData,
  hideFormModalIfNoErrors,
  setFormLoadingState,
} from '@app/shared/components/radar-form-abstract';
import { ModalV2Service } from '@app/ui-v2/services/modal-v2.service';
import { OrderBookingEticketsV2Service } from './services/order-booking-etickets-v2.service';
import { GraphqlID } from '@app/shared/types';
import { OrdersPageV2Service } from '@app/pages/orders/pages/order-details-page-v2/services/orders-page-v2.service';
import { openNewTab } from '@app/pages/orders/pages/order-details-page-v2/utils/pdf';

@Component({
  selector: 'order-booking-etickets-section',
  templateUrl: './order-booking-etickets-section.component.html',
  styleUrls: ['./order-booking-etickets-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderBookingEticketsSectionComponent {
  UserType = UserType;

  editableETicket: ETicketNode;

  @Input() orderId: GraphqlID;
  @Input() bookingId: GraphqlID;
  @Input() readonly: boolean;
  @Input() eTickets: ETicketNode[] = [];

  constructor(
    private orderBookingEticketService: OrderBookingEticketsV2Service,
    private ordersPageV2Service: OrdersPageV2Service,
    private modalV2Service: ModalV2Service,
  ) {}

  editETicket(eTicket: ETicketNode, template: TemplateRef<unknown>) {
    this.editableETicket = eTicket;
    this.modalV2Service.showModal({ template });
  }

  saveETicket(formData: FormSubmitData<UpsertOrderETicketMutationVariables>) {
    const params = mapETicketFormData(formData, this.bookingId);

    this.orderBookingEticketService
      .upsertOrderETicket(params)
      .pipe(hideFormModalIfNoErrors(), setFormLoadingState(false))
      .subscribe(({ data }) =>
        this.ordersPageV2Service.setOrder(data?.upsertOrderETicket?.result as OrderNode),
      );
  }

  deleteETicket(eTicketId: GraphqlID | undefined) {
    if (eTicketId) {
      const params = { eTicketId, orderId: this.orderId };

      this.orderBookingEticketService
        .deleteOrderETicket(params)
        .pipe(hideFormModalIfNoErrors(), setFormLoadingState(false))
        .subscribe(({ data }) =>
          this.ordersPageV2Service.setOrder(data?.deleteOrderETicket?.result as OrderNode),
        );
    }
  }

  downloadETicket(fileETicketId: GraphqlID | undefined) {
    if (fileETicketId) {
      const data = { fileETicketId };

      this.orderBookingEticketService
        .getPresignedUrl(data)
        .subscribe(({ data }) => openNewTab(data?.getPresignedUrl));
    }
  }
}

const mapETicketFormData = ({ data, id }: FormSubmitData, bookingId: GraphqlID) => {
  const initial = {
    fileAction: FileActionEnum.NoChanges,
    bookingId,
    id,
  };

  const params = Object.assign(initial, data);
  let file = undefined;

  if (params.fileETicket?.id === '') {
    params.fileAction = FileActionEnum.Delete;
  } else if (params.fileETicket?.id instanceof FileList) {
    file = params.fileETicket?.id[0];
    params.fileAction = FileActionEnum.Update;
  }

  delete params.fileETicket;

  return { params, file };
};
