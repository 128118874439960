import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BookingNode, FlightItineraryGroupNode, FlightSegmentNode } from '@app/generated/graphql';
import { GraphqlID } from '@app/shared/types';

@Component({
  selector: 'itinerary-v2-flights',
  templateUrl: './itinerary-v2-flights.component.html',
  styleUrls: ['./itinerary-v2-flights.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItineraryV2FlightsComponent {
  @Input() flightItineraries?: FlightItineraryGroupNode[];
  @Input() booking: BookingNode;

  @Input() showSeatsSelection: boolean;
  @Input() readonly: boolean;
  @Input() readonlyExtra: boolean;

  @Output() selectSeats = new EventEmitter<FlightSegmentNode>();
  @Output() toggleExtra = new EventEmitter();

  setExtra(isExtraLeg: boolean, id?: GraphqlID) {
    this.toggleExtra.emit({ isExtraLeg, id });
  }
}
