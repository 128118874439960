import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import {
  AirlineConfirmationNode,
  CreateAirlineConfirmationMutationVariables,
  UpdateAirlineConfirmationMutationVariables,
} from '@app/generated/graphql';
import {
  FormSubmitData,
  hideFormModalIfNoErrors,
  setFormLoadingState,
} from '@app/shared/components/radar-form-abstract';
import { ModalV2Service } from '@app/ui-v2/services/modal-v2.service';
import { OrderBookingAirlineConfirmationV2Service } from './services/order-booking-confirmation-v2.service';
import { GraphqlID } from '@app/shared/types';
import { OrdersPageV2Service } from '@app/pages/orders/pages/order-details-page-v2/services/orders-page-v2.service';

@Component({
  selector: 'order-booking-confirmation-section',
  templateUrl: './order-booking-confirmation-section.component.html',
  styleUrls: ['./order-booking-confirmation-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderBookingConfirmationSectionComponent {
  editableAirlineConfirmation: AirlineConfirmationNode;

  @Input() airlineConfirmations: AirlineConfirmationNode[] = [];
  @Input() readonly: boolean;
  @Input() bookingId: GraphqlID;
  @Input() orderId: GraphqlID;

  constructor(
    private modalV2Service: ModalV2Service,
    private orderBookingAirlineConfirmationV2Service: OrderBookingAirlineConfirmationV2Service,
    private ordersPageV2Service: OrdersPageV2Service,
  ) {}

  updatePayment(airlineConfirmation: AirlineConfirmationNode, template: TemplateRef<unknown>) {
    if (this.readonly) return;

    this.editableAirlineConfirmation = airlineConfirmation;
    this.modalV2Service.showModal({ template });
  }

  createAirlineConfirmation({ data }: FormSubmitData<CreateAirlineConfirmationMutationVariables>) {
    const params = {
      ...data,
      bookingId: this.bookingId,
    };

    this.orderBookingAirlineConfirmationV2Service
      .createAirlineConfirmation(params)
      .pipe(hideFormModalIfNoErrors(), setFormLoadingState(false))
      .subscribe(() => this.ordersPageV2Service.getOrder(this.orderId));
  }

  updateAirlineConfirmation({
    data,
    id,
  }: FormSubmitData<UpdateAirlineConfirmationMutationVariables>) {
    const params = { ...data, id: id as string };

    this.orderBookingAirlineConfirmationV2Service
      .updateAirlineConfirmation(params)
      .pipe(hideFormModalIfNoErrors(), setFormLoadingState(false))
      .subscribe(() => this.ordersPageV2Service.getOrder(this.orderId));
  }

  deleteAirlineConfirmation(id: GraphqlID | undefined) {
    if (id) {
      const params = { id };

      this.orderBookingAirlineConfirmationV2Service
        .deleteAirlineConfirmation(params)
        .pipe(hideFormModalIfNoErrors(), setFormLoadingState(false))
        .subscribe(() => this.ordersPageV2Service.getOrder(this.orderId));
    }
  }
}
