import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import {
  CreateNoteGQL,
  LeadNoteEdge,
  LeadNotesDocument,
  LeadNotesGQL,
} from '@app/generated/graphql';
import { NotesComponent } from '@app/shared/components/notes/notes.component';
import { QUERIES_LIMIT } from '@app/shared/constants';
import { scrollToLastItem } from '@app/shared/utils/scroll-utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'communication-container-notes',
  templateUrl: './communication-container-notes.component.html',
  styleUrls: ['./communication-container-notes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommunicationContainerNotesComponent extends NotesComponent {
  @Input() leadId? = '';

  notes$: Observable<LeadNoteEdge[]>;

  constructor(
    private leadNotesGQL: LeadNotesGQL,
    private createLeadNoteGQL: CreateNoteGQL,
  ) {
    super();
  }

  loadNotes(): void {
    const params = {
      leadId: this.leadId,
      last: QUERIES_LIMIT,
    };

    this.notes$ = this.leadNotesGQL.watch(params, this.options).valueChanges.pipe(
      map(({ data }) => {
        setTimeout(() => scrollToLastItem('.note'));
        return data.leadNotes?.edges as LeadNoteEdge[];
      }),
    );
  }

  addComment(text: string) {
    const params = {
      leadId: this.leadId as string,
      text,
    };

    this.createLeadNoteGQL.mutate(params, { refetchQueries: [LeadNotesDocument] }).subscribe(() => {
      this.formControl.setValue('');
      this.resize();
    });
  }
}
