import { Injectable } from '@angular/core';
import { GraphqlID } from '@app/shared/types';
import { LoadPassengersByOrderGQL, PassengerNode } from '@generated/graphql';
import { fetchAsDropDownItems } from '@shared/services/dropdown-fetch';
import { DropdownItem } from '@ui/components/dropdown.model';
import { Observable } from 'rxjs';

const dropDownMapper = (node: PassengerNode): DropdownItem => {
  return { value: node.id, label: node.fullName };
};

@Injectable({
  providedIn: 'root',
})
export class PassengersService {
  constructor(private loadPassengersByOrderGQL: LoadPassengersByOrderGQL) {}

  loadPassengersForOrder(orderId: GraphqlID): Observable<DropdownItem[]> {
    return fetchAsDropDownItems(
      this.loadPassengersByOrderGQL,
      ({ data }) => data.order.passengers,
      dropDownMapper,
      { orderId },
    );
  }
}
