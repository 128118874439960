import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { isObject } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class RoutingService {
  constructor(private router: Router) {}

  navigateTo(
    params: string[],
    event?: KeyboardEvent | MouseEvent,
    extras?: NavigationExtras,
  ): void {
    if (event?.metaKey || event?.ctrlKey) {
      const searchParams = new URLSearchParams();

      if (
        extras?.queryParams &&
        isObject(extras?.queryParams) &&
        Object.keys(extras?.queryParams)?.length
      ) {
        Object.keys(extras.queryParams).forEach((key) => {
          if (extras.queryParams) {
            const value = extras.queryParams[key] as string;
            searchParams.append(key, value);
          }
        });
      }

      const queryParams = searchParams.toString();

      window.open(`${params.join('/')}${queryParams ? `?${queryParams}` : ''}`, '_blank');
    } else {
      void this.router.navigate(params, extras);
    }
  }
}
