<card>
  <typography-v2 look="title-bold" class="v2-mb-3">Orders</typography-v2>

  <ng-container *ngIf="orders && columns; else loadingTemplate">
    <div *ngIf="orders.length; else emptyState" class="d-flex flex-column">
      <radar-table
        class="w-100"
        [columns]="columns"
        [rows]="orders"
        [readonly]="true"
        (rowClick)="orderClick($event)"
      >
      </radar-table>
    </div>
  </ng-container>
</card>

<ng-template #emptyState>
  <typography-v2 class="text-black-50">Has no orders yet</typography-v2>
</ng-template>

<ng-template #loadingTemplate>
  <ng-container *ngFor="let x of [].constructor(3)">
    <div class="d-flex v2-pt-2 v2-pb-2 w-100">
      <skeleton-loader-small class="v2-mx-2" [height]="16" [width]="160"></skeleton-loader-small>
      <skeleton-loader-small class="v2-mx-2" [height]="16" [width]="80"></skeleton-loader-small>
      <skeleton-loader-small class="v2-mx-2" [height]="16" [width]="110"></skeleton-loader-small>
      <skeleton-loader-small class="v2-mx-2" [height]="16" [width]="90"></skeleton-loader-small>
      <skeleton-loader-small class="v2-mx-2" [height]="16" [width]="100"></skeleton-loader-small>
      <skeleton-loader-small class="v2-mx-2" [height]="16" [width]="100"></skeleton-loader-small>
      <skeleton-loader-small class="v2-mx-2" [height]="16"></skeleton-loader-small>
    </div>
  </ng-container>
</ng-template>

<ng-template #statusTemplate let-status="value" let-order="row">
  <colored-status-v2 data-id="status" [status]="status">{{ status | label }}</colored-status-v2>
</ng-template>

<ng-template #orderAdditional let-order="row">
  <div class="d-flex w-100 align-items-center justify-content-between">
    <ng-container *ngIf="order.passengers?.edges | extractNodes as passengers">
      <div
        *ngIf="passengers?.length"
        [tooltip]="tooltipTemplate"
        placement="right"
        container="body"
        class="d-flex ms-auto"
      >
        <rdr-svg-icon key="user-v-2" color="var(--color-black-30)"></rdr-svg-icon>

        <ng-template #tooltipTemplate>
          <div class="d-flex flex-column">
            <typography-v2 *ngFor="let passenger of passengers" look="caption-bold"
              >{{ passenger.fullName }}</typography-v2
            >
          </div>
        </ng-template>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #orderChangeTypeTemplate let-type="value">
  <typography-v2 *ngIf="type !== OrderType.Issuance">{{ type | label }}</typography-v2>
</ng-template>

<ng-template #flightItineraryTemplate let-firstFlightItinerary="value">
  <typography-v2 *ngIf="firstFlightItinerary" dataId="flight"
    >{{ firstFlightItinerary?.departureAirport?.locationCode || '-' }} &rarr; {{
    firstFlightItinerary?.arrivalAirport?.locationCode || '-' }}</typography-v2
  >
</ng-template>

<ng-template #flightDateTemplate let-firstFlightItinerary="value">
  <typography-v2 *ngIf="firstFlightItinerary" dataId="departure-at"
    >{{ firstFlightItinerary.departureAt | rdrDate: 'short_date'}}
  </typography-v2>
</ng-template>

<ng-template #soldAmountTemplate let-financeInfo="value">
  <typography-v2 *ngIf="financeInfo" dataId="sold-amount"
    >{{ financeInfo.soldAmount.amount | rdrCurrency }}
  </typography-v2>
</ng-template>

<ng-template #grossProfitTemplate let-financeInfo="value">
  <typography-v2 *ngIf="financeInfo" dataId="gross-profit"
    >{{ financeInfo.actualGrossProfit.amount | rdrCurrency }}
  </typography-v2>
</ng-template>

<ng-template #totalCostTemplate let-financeInfo="value">
  <typography-v2 *ngIf="financeInfo" dataId="total-cost"
    >{{ financeInfo.totalCost.amount | rdrCurrency }}
  </typography-v2>
</ng-template>
