import { Injectable } from '@angular/core';
import {
  CreateAirlineConfirmationGQL,
  UpdateAirlineConfirmationGQL,
  DeleteAirlineConfirmationGQL,
  CreateAirlineConfirmationMutationVariables,
  UpdateAirlineConfirmationMutationVariables,
  DeleteAirlineConfirmationMutationVariables,
} from '@app/generated/graphql';

@Injectable({
  providedIn: 'root',
})
export class OrderBookingAirlineConfirmationV2Service {
  constructor(
    private createAirlineConfirmationGQL: CreateAirlineConfirmationGQL,
    private updateAirlineConfirmationGQL: UpdateAirlineConfirmationGQL,
    private deleteAirlineConfirmationGQL: DeleteAirlineConfirmationGQL,
  ) {}

  createAirlineConfirmation(params: CreateAirlineConfirmationMutationVariables) {
    return this.createAirlineConfirmationGQL.mutate(params);
  }

  updateAirlineConfirmation(params: UpdateAirlineConfirmationMutationVariables) {
    return this.updateAirlineConfirmationGQL.mutate(params);
  }

  deleteAirlineConfirmation(params: DeleteAirlineConfirmationMutationVariables) {
    return this.deleteAirlineConfirmationGQL.mutate(params);
  }
}
