import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  EmailMessageNode,
  FlightRequestStatusInteraction,
  FlightRequestStatusType,
  GetInteractionsQueryVariables,
  Interaction,
  InteractionType,
  LeadMessageNode,
  LeadStatusInteraction,
  LeadStatusType,
  OrderStatusInteraction,
  OrderStatusType,
  WholeCalls,
} from '@app/generated/graphql';
import { InteractionsService } from '../../services/interactions/interactions.service';
import {
  isEmailMessageNode,
  isFlightRequestStatusInteraction,
  isLeadMessageNode,
  isLeadStatusInteraction,
  isOrderStatusInteraction,
  isWholeCalls,
} from '../../utils/utils';
import { DropdownItem } from '@app/ui/components/dropdown.model';
import { mapEnumToDropdownItems } from '@app/shared/utils/dropdown-utils';

@Component({
  selector: 'interactions',
  templateUrl: './interactions.component.html',
  styleUrls: ['./interactions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InteractionsComponent implements OnInit {
  interactions: Interaction[] = [];

  limit = 15;
  offset = 0;

  loading: boolean;
  loadedAll: boolean;

  communicationTypes = mapEnumToDropdownItems(InteractionType, { withoutColors: true });

  selectedCommunicationTypes: DropdownItem[] = [];

  @Input() leadId? = '';

  constructor(
    private interactionsService: InteractionsService,
    private cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.getInteractions();
  }

  scrollToEvent() {
    const lastElement = document.querySelector(`[data-index="${this.offset + 1}"]`) as HTMLElement;
    const scrollableHost = document.getElementById('scrollable') as HTMLElement;

    const behavior = this.offset > 0 ? undefined : 'smooth';

    scrollableHost?.scrollTo({ top: lastElement?.offsetTop, behavior });
  }

  getInteractions() {
    this.loading = true;

    const types = this.selectedCommunicationTypes.map(({ value }) => value as InteractionType);

    const params: GetInteractionsQueryVariables = {
      limit: this.limit,
      offset: this.offset,
      leadId: this.leadId as string,
      filters: types?.length ? { types } : {},
    };

    this.interactionsService.getInteractions(params).subscribe((interactions: Interaction[]) => {
      this.interactions = this.interactions.concat(interactions);

      this.loading = false;
      this.loadedAll = interactions.length != this.limit;

      this.cdRef.detectChanges();

      setTimeout(() => this.scrollToEvent());
    });
  }

  loadMore() {
    this.offset = this.offset + this.limit;
    this.getInteractions();
  }

  isWholeCalls(interaction: Interaction): interaction is WholeCalls {
    return isWholeCalls(interaction);
  }

  isLeadMessageNode(interaction: Interaction): interaction is LeadMessageNode & { body: string } {
    return isLeadMessageNode(interaction);
  }

  isEmailMessageNode(interaction: Interaction): interaction is EmailMessageNode {
    return isEmailMessageNode(interaction);
  }

  isLeadStatusInteraction(interaction: Interaction): interaction is LeadStatusInteraction & {
    leadStatus: LeadStatusType;
    leadPreviousStatus: LeadStatusType;
  } {
    return isLeadStatusInteraction(interaction);
  }

  isFlightRequestStatusInteraction(
    interaction: Interaction,
  ): interaction is FlightRequestStatusInteraction & {
    flightRequestStatus: FlightRequestStatusType;
    flightRequestPreviousStatus: FlightRequestStatusType;
  } {
    return isFlightRequestStatusInteraction(interaction);
  }

  isOrderStatusInteraction(interaction: Interaction): interaction is OrderStatusInteraction & {
    orderStatus: OrderStatusType;
    orderPreviousStatus: OrderStatusType;
  } {
    return isOrderStatusInteraction(interaction);
  }

  setCommunicationType(item: DropdownItem) {
    const foundIndex = this.selectedCommunicationTypes.findIndex(
      (type) => type.value === item.value,
    );

    if (foundIndex > -1) {
      this.selectedCommunicationTypes.splice(foundIndex, 1);
    } else {
      this.selectedCommunicationTypes.push(item);
    }

    this.selectedCommunicationTypes = [...this.selectedCommunicationTypes];

    this.offset = 0;
    this.interactions = [];

    this.getInteractions();
  }
}
