import { Injectable } from '@angular/core';
import {
  ClientEdge,
  ClientNode,
  ClientNodeSortEnum,
  ClientsGQL,
  ClientsQuery,
  ClientsQueryVariables,
  GetClientsBySalesIdsGQL,
} from '@generated/graphql';
import { map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ApolloQueryResult } from '@apollo/client/core';
import { TeamMembersService } from '../team-members/team-members.service';
import { CognitoAuthService } from '@auth/services/cognito-auth.service';
import { DropdownItem, EMPTY_DROP_DOWN_ITEM } from '@app/ui/components/dropdown.model';
import { UserNamePipe } from '@app/ui/pipes/user-name.pipe';
import { AppInjector } from '@app/app-injector.service';

@Injectable({
  providedIn: 'root',
})
export class ClientsService {
  constructor(
    private clientsGql: ClientsGQL,
    private getClientsBySalesIdsGQL: GetClientsBySalesIdsGQL,
    private teamMembersService: TeamMembersService,
    private cognitoAuthService: CognitoAuthService,
  ) {}

  getClientsForSalesTeamLead(
    data: ClientsQueryVariables,
  ): Observable<ApolloQueryResult<ClientsQuery>> {
    return this.clientsGql.watch(data).valueChanges;
  }

  getClientsBySalesIds(search = '', includeEmpty = false): Observable<DropdownItem[]> {
    const currentUserId = this.cognitoAuthService.user.id;
    const isSalesTeamLead = this.cognitoAuthService.isSalesTeamLead;

    return this.teamMembersService.getTeamMembers(currentUserId).pipe(
      switchMap((users = []) => {
        const ids = users.map((user) => user.id);

        const params: ClientsQueryVariables = {
          filters: {
            and: [
              { salesAgentIdIn: [...(isSalesTeamLead ? ids : []), currentUserId] },
              ...(search ? [{ name: search }] : []),
            ],
          },
          sort: [ClientNodeSortEnum.LastNameAsc],
          first: 20,
        };

        return this.getClientsBySalesIdsGQL.fetch(params, { fetchPolicy: 'cache-first' }).pipe(
          map(({ data }) => {
            const clients = ((data.clients?.edges as ClientEdge[]) || [])?.map(({ node }) =>
              mapReferenceClientToDDItem(node as ClientNode),
            );

            if (
              includeEmpty &&
              EMPTY_DROP_DOWN_ITEM.label?.includes(search?.trim().toLocaleLowerCase())
            ) {
              clients.unshift(EMPTY_DROP_DOWN_ITEM);
            }
            return clients;
          }),
        );
      }),
    );
  }
}

export const mapReferenceClientToDDItem = (client: ClientNode): DropdownItem => {
  return {
    label: getClientLabel(client),
    value: client?.id,
  };
};

const getClientLabel = (client: ClientNode): string => {
  const userNamePipe = AppInjector.injector.get(UserNamePipe);
  return userNamePipe.transform(client);
};
