import {
  Component,
  ChangeDetectionStrategy,
  Input,
  AfterViewInit,
  ViewChild,
  TemplateRef,
  ChangeDetectorRef,
} from '@angular/core';
import { SupportRequestNode } from '@app/generated/graphql';
import { RoutingService } from '@app/shared/services/routing.service';
import { RadarTableColumn, RadarTableRowClick } from '../radar-table/radar-table.types';
import { MainLayoutStyleService } from '@app/layout/main-layout/main-layout-style.service';

@Component({
  selector: 'support-requests-section',
  templateUrl: './support-requests-section.component.html',
  styleUrls: ['./support-requests-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupportRequestsSectionComponent implements AfterViewInit {
  @ViewChild('createdAtTemplate') createdAtTemplate: TemplateRef<any>;
  @ViewChild('salesAgentTemplate') salesAgentTemplate: TemplateRef<any>;
  @ViewChild('ticketingAgentTemplate') ticketingAgentTemplate: TemplateRef<any>;

  @Input() title: string;
  @Input() items: SupportRequestNode[];
  @Input() isTicketing: boolean;

  columns: RadarTableColumn[];

  constructor(
    private routingService: RoutingService,
    private cdr: ChangeDetectorRef,
    private mainLayoutService: MainLayoutStyleService,
  ) {}

  ngAfterViewInit(): void {
    this.mainLayoutService.enableV2();
    const orderIdCol = this.isTicketing
      ? [
          {
            name: 'Order ID',
            prop: 'orderId',
            width: 90,
          },
        ]
      : [];

    const salesAgentCol = this.isTicketing
      ? [
          {
            name: 'Sales Agent',
            prop: 'saleAgent',
            cellTemplate: this.salesAgentTemplate,
            width: 180,
          },
        ]
      : [];

    this.columns = [
      {
        name: 'Type',
        prop: 'type.name',
      },
      ...orderIdCol,
      {
        name: 'Description',
        prop: 'description',
      },
      {
        name: 'Response',
        prop: 'response',
      },
      {
        name: 'Ticketing Agent',
        prop: 'ticketingAgent',
        cellTemplate: this.ticketingAgentTemplate,
        width: 180,
      },
      ...salesAgentCol,
      {
        name: 'Created',
        prop: 'createdAt',
        cellTemplate: this.createdAtTemplate,
        width: 150,
      },
    ];

    this.cdr.detectChanges();
  }

  onClick({ event, row }: RadarTableRowClick<SupportRequestNode>): void {
    event.type === 'click' && this.routingService.navigateTo(['orders', row.orderId], event);
  }
}
