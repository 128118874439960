import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { AIRLINES_V2, FILE_V2, INPUT_V2, SELECT_V2 } from '@app/formly/helpers/fields';
import { AirlineNode } from '@app/generated/graphql';
import { RadarFormAbstract } from '@app/shared/components/radar-form-abstract';
import { AirlinesService, mapAirlineToDD } from '@app/shared/services/airlines/airlines.service';
import { PassengersService } from '@app/shared/services/passengers/passengers.service';
import { GraphqlID } from '@app/shared/types';

@Component({
  selector: 'e-ticket-form',
  templateUrl: './e-ticket-form.component.html',
  styleUrls: ['./e-ticket-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ETicketFormComponent extends RadarFormAbstract implements OnInit {
  @Input() orderId: GraphqlID;

  @Output() deleteETicket = new EventEmitter();

  constructor(
    private airlinesService: AirlinesService,
    private passengersService: PassengersService,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.fields = [
      SELECT_V2(
        {
          key: 'passengerId',
          props: {
            label: 'Passenger',
            placeholder: 'Choose a passenger',
            initial: this.data.passenger
              ? {
                  value: this.data.passenger.id,
                  label: this.data.passenger.fullName,
                }
              : null,
          },
        },
        this.passengersService.loadPassengersForOrder(this.orderId),
      ),
      AIRLINES_V2(
        {
          key: 'airlineId',
          props: {
            label: 'Airline',
            placeholder: 'Choose a airline',
            initial: this.data?.airline ? mapAirlineToDD(this.data?.airline as AirlineNode) : null,
          },
        },
        this.airlinesService.getAirlines(),
      ),
      INPUT_V2({
        key: 'codeNumber',
        props: {
          label: 'Ticket Number',
          placeholder: 'Write a ticket number',
        },
      }),
      FILE_V2({
        key: 'fileETicket.id',
        props: {
          label: 'File',
          filetype: 'fileETicketId',
          originName: this.data?.fileETicket?.originName,
        },
      }),
    ];
  }
}
